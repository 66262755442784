import apartments from 'modules/apartments';
import app from 'modules/app';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  [app.constants.NAME]: app.reducer,
  [apartments.constants.NAME]: apartments.reducer,
});

export default rootReducer;
