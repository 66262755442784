import 'styles/index.css';

import App from 'next/app';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';
import { appWithTranslation } from 'i18n';
import { compose } from 'redux';
import createStore from 'store';
import withRedux from 'next-redux-wrapper';
import { initGA, logPageView } from '../utils/analytics';

const meta = {
  title: 'Rezydencja Pod Świerkami - Apartamenty Karpacz, zarezerwuj apartamenty w Karpaczu',
  description:
    // eslint-disable-next-line
    'Rezydencja Pod Świerkami to wspaniałe apartamenty dla osób lubiących wysoki standard i wygodę. Nowoczesne apartamenty położone są w centrum Karpacza. Każdy apartament posiada w pełni wyposażoną kuchnię oraz łazienkę. Do każdego apartamentu przydzilone jest miejsce w parkingu podziemmnym.',
  keywords:
    // eslint-disable-next-line
    'Apartamenty Karpacz, kwatery Karpacz, noclegi Karpacz, rezydencja w Karpaczu, apartamenty w Karpaczu, noclegi w Karpaczu, kwatery w Karpaczu, apartament Karpacz, nocleg Karpacz, noclegi w apartamentach, rezerwacja apartamentów, kwatery w apartamentowcu',
};

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    return {
      pageProps: Component.getInitialProps ? await Component.getInitialProps(ctx) : {},
      namespacesRequired: ['common'],
    };
  }

  componentDidMount() {
    initGA();
  }

  render() {
    const { Component, pageProps, store } = this.props;

    return (
      <>
        <Helmet
          meta={[
            { name: 'charset', content: 'utf-8' },
            {
              name: 'viewport',
              content: 'width=device-width, maximum-scale=1.0, initial-scale=1.0, user-scalable=0',
            },
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
            { name: 'format-detection', content: 'telephone=no' },
          ]}
          title={meta.title}
        >
          <link href="/static/images/apple-touch-icon.png" rel="apple-touch-icon" />
          <meta content={meta.description} name="description" />
          <meta content={meta.keywords} name="keywords" />
          <meta content="/static/images/og-image.jpg" property="og:image" />
          <meta content="1710" property="og:image:width" />
          <meta content="895" property="og:image:height" />
        </Helmet>
        <Provider store={store}>
          <Component {...pageProps} />
        </Provider>
      </>
    );
  }
}

export default compose(withRedux(createStore), appWithTranslation)(MyApp);
