import { applyMiddleware, createStore } from 'redux';

import rootReducer from 'reducer';
import thunk from 'redux-thunk';

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

const configureStore = (initialState = {}) => {
  const store = createStore(rootReducer, initialState, bindMiddleware([thunk]));

  return store;
};

export default configureStore;
